import { connect } from "react-redux";
import { Button } from "../components/button/button";
import { Layout } from "../components/layout/layout";
import SearchForm from "../components/search-form/search-form";
import * as Actions from "../actions/actions";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow-right.svg";
import { ReactComponent as SuitcaseIcon } from "../assets/images/icon-brokerage.svg";
import { ReactComponent as CartIcon } from "../assets/images/icon-buy-yachts.svg";
import { ReactComponent as TagIcon } from "../assets/images/icon-sell-yachts.svg";
import arrowLeft from "../assets/images/arrow-left.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import "./home.scss";
import { BASE_WEBSITE_URL } from "../utils/constants";
import { BoatCard } from "../components/boat-card/boat-card";
import { useCallback, useEffect, useState } from "react";
import { Paginator } from "../components/paginator/paginator";
import CategoryForm from "../components/category-form/category-form";
import FiltersForm from "../components/filters-form/filters-form";
import { SearchSelect } from "../components/search-select/search-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from 'query-string'

const ENABLE_LINK = false;

const SORTING_OPTIONS = [
  {
    id: 2,
    label: "Price (Low to High)"
  },
  {
    id: 3,
    label: "Price (High to Low)"
  },
  {
    id: 1,
    label: "Length (Low to High)"
  },
  {
    id: 0,
    label: "Length (High to Low)"
  },
  {
    id: 6,
    label: "Name (A-Z)"
  },
  {
    id: 7,
    label: "Name (Z-A)"
  },
];

function Home ({
  onToggleSearchDrawer,
  onFetchFeaturedBoats,
  onPageChange,
  searchResults,
  featuredResults,
  sortBy,
  onAddSortParameter,
  onSearch,
  onSearchFirstLoad,
  manufacturers,
  isPagerVisible,
  isFirstLoad
}) {
  const [current, setCurrent] = useState(0);

  const onChangeHandler = (value) => {
    onAddSortParameter(value);
  };

  const { innerWidth: width } = window;

  const boats = searchResults?.results || [];
  const featuredBoats = featuredResults?.results || [];
  const totalCount = searchResults?.totalCount || 0;
  const totalPages = searchResults?.totalPages || 1;
  const currentPage = searchResults?.currentPage || 1;
  const { search } = useLocation();

  // Effect to fetch data
  useEffect(() => {
    const defaultFilters = queryString.parse(search);

    if (isFirstLoad && !Object.keys(defaultFilters).length) {
      onSearchFirstLoad()
    }

    onFetchFeaturedBoats();
  }, [isFirstLoad, onFetchFeaturedBoats, onSearchFirstLoad, search]);

  const handleOnSearch = useCallback((filters) => {
    onSearch(filters);

    const element = document.getElementById("home-content");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

  }, [onSearch])

  useEffect(() => {
    if (!manufacturers.length) {
      return
    }

    const defaultFilters = queryString.parse(search);

    if (!Object.keys(defaultFilters).length) {
      return
    }

    if (!defaultFilters.id) {
      handleOnSearch(defaultFilters);
      return
    }

    const manufacturer = manufacturers.find(({ id }) => id === +defaultFilters.id)

    const { minLength,
      maxLength,
      minPrice,
      maxPrice,
      minYear,
      maxYear } = defaultFilters

    const standardizedFilters = {
      minLength,
      maxLength,
      minPrice,
      maxPrice,
      minYear,
      maxYear,
      manufacturer
    };

    handleOnSearch(standardizedFilters);
  }, [manufacturers, handleOnSearch, search, onSearchFirstLoad])

  const renderSearch = () => {
    return (
      <>
        <div className="my-home-banner__mobile">
          <Button onClick={onToggleSearchDrawer} text="Advanced Search" />
        </div>
        <div className="my-home-banner__desktop">
          <SearchForm
            isDesktop
            onSearch={handleOnSearch}
          />
        </div>
      </>
    );
  };

  const handleOnPageChange = async (page = 1) => {
    onPageChange(page);
  };

  let limit = featuredBoats.length - 1;
  let gap = 16;
  if (width > 768) {
    limit = featuredBoats.length / 3 - 1;
  }

  if (width > 1293) {
    gap = 32;
  }

  const onNext = () => {
    if (current < limit) {
      setCurrent(current + 1);

      return;
    }

    setCurrent(0);
  };

  const onPrevious = () => {
    if (current > 0) {
      setCurrent(current - 1);
      return;
    }

    setCurrent(limit);
  };

  return (
    <Layout
      title="Yacht Sales"
      bannerBottomAligned={false}
      hideBannerArrows={false}
      bannerContent={renderSearch()}
    >
      <div className="my-home">
        <div
          className={`my-home-featured ${featuredBoats.length === 0 ? "my-home-featured--no-results" : ""
            }`}
        >
          <span className="my-home-featured__title">
            Featured Yachts & Boats
          </span>
          <div className="my-home-featured-content">
            <button className="my-home-featured__prev" onClick={onPrevious}>
              <img src={arrowLeft} alt="Previous" />
            </button>

            <div className="my-home-featured-items">
              <div
                className="my-home-featured-slider"
                style={{
                  transform: `translateX(calc(-${current}00% - ${gap * current
                    }px)`,
                }}
              >
                {featuredBoats.map((boat) => {
                  return (
                    <div className="my-home-featured__item" key={boat.id}>
                      <BoatCard {...boat} />
                    </div>
                  );
                })}
              </div>
            </div>

            <button className="my-home-featured__next" onClick={onNext}>
              <img src={arrowRight} alt="Next" />
            </button>
          </div>
          <div className="my-home-featured-actions">
            <button className="my-home-featured__button" onClick={onPrevious}>
              <img src={arrowLeft} alt="Previous" />
            </button>
            <button className="my-home-featured__button" onClick={onNext}>
              <img src={arrowRight} alt="Next" />
            </button>
          </div>
        </div>
        <div id="home-content" className="my-home-content">
          {ENABLE_LINK && (
            <a className="my-home__link" href={`${BASE_WEBSITE_URL}/sales`}>
              Buying a yacht <ArrowIcon stroke="#07215C" height={12} />
            </a>
          )}
          <div className="my-home-category">
            <span className="my-home__category">Find by category</span>
            <CategoryForm />
          </div>
          <div className="my-home-filters">
            <span className="my-home__total-count">
              {totalCount} results match your criteria
            </span>
            <FiltersForm />
          </div>
          <div className="my-home-sorting">
            {!!boats?.length && (
              <div className="my-home-sorting__field">
                <span className="my-home-sorting__label">Sort by</span>
                <SearchSelect
                  placeholder="Select sorting"
                  value={sortBy}
                  onChange={onChangeHandler}
                  options={SORTING_OPTIONS}
                />
              </div>
            )}
            {!!boats?.length && (
              <div className="my-home-paginator">
                {isPagerVisible && <Paginator
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onChange={handleOnPageChange}
                />}

              </div>
            )}
            <div className="my-home-results">
              {boats?.map((boat) => {
                return <BoatCard {...boat} key={boat.id} />;
              })}
            </div>
            {!!boats?.length && (
              <div className="my-home-paginator">
                {isPagerVisible && <Paginator
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onChange={handleOnPageChange}
                />}
              </div>
            )}
          </div>
        </div>
        <div className="my-home-footer">
          <span className="my-home-footer__title">
            More about buying a yacht
          </span>
          <div className="my-home-footer-content">
            <a
              className="my-home-footer__section"
              key="Brokerage"
              href={`${BASE_WEBSITE_URL}/sales#brokerage`}
            >
              <SuitcaseIcon className="my-home-footer__icon" />
              <span>Brokerage</span>
            </a>
            <a
              className="my-home-footer__section"
              key="Buying a Yacht"
              href={`${BASE_WEBSITE_URL}/sales#buying-yachts`}
            >
              <CartIcon className="my-home-footer__icon" />
              <span>Buying a Yacht</span>
            </a>
            <a
              className="my-home-footer__section"
              key="Selling a Yacht"
              href={`${BASE_WEBSITE_URL}/sales#selling-yachts`}
            >
              <TagIcon className="my-home-footer__icon" />
              <span>Selling a Yacht</span>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    sortBy: state.sortBy,
    categoryFilters: state.categoryFilters,
    searchResults: state.searchResults,
    featuredResults: state.featuredBoats,
    manufacturers: state.manufacturers,
    isPagerVisible: state.isPagerVisible,
    isFirstLoad: state.isFirstLoad
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    onSearch: (filters) =>
      dispatch(Actions.triggerFiltersSearch({ page: 1, filters })),
    onSearchFirstLoad: () =>
      dispatch(Actions.triggerFirstLoadSearch()),
    onToggleSearchDrawer: () => dispatch(Actions.toggleSearchDrawer()),
    onPageChange: (page) => dispatch(Actions.goToResultsPage(page)),
    onFetchFeaturedBoats: () => dispatch(Actions.fetchFeaturedBoats()),
    onAddSortParameter: (parameter) => dispatch(Actions.addSortParameter(parameter)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Home);
