// Imports
import {
  YATCO_API_MIAMI_YACHTING_COMPANY_ID,
  YATCO_API_SUBCATEGORY_CENTER_CONSOLE_ID,
} from "../utils/constants";
import { get, post } from "./axios";

const RESULTS_PER_PAGE = 20;
const RESULTS_MAX_RECORDS_FEATURED = 100;
const RESULTS_MAX_RECORDS_CUSTOM_FILTER = 500;

// Get a list of boats using the API
const getAll = async (page = 1, filters = {}, category, type, sortBy) => {
  const offset = (page - 1) * RESULTS_PER_PAGE;
  const data = await post("/ForSale/Vessel/Search", {
    Records: RESULTS_PER_PAGE,
    Offset: offset,
    sortId: sortBy?.id,
    ...mapFiltersToSearch(filters),
    ...mapTypeToSearch(type),
    ...mapCategoryToSearch(category),
  });
  if (data) {
    const results = data.Results.map(mapSearchVesselToBoat);
    const totalPages = Math.ceil(data.Count / data.Records);
    const currentPage = data.Offset / data.Records + 1;
    return {
      results,
      totalCount: data.Count || 0,
      totalPages,
      currentPage,
    };
  }
};

const getFirstLoadData = async () => {
  const data = await post("/ForSale/Vessel/Search", {
    Records: RESULTS_MAX_RECORDS_CUSTOM_FILTER,
    Offset: 0,
    sortId: undefined,
    BuilderID: undefined,
    LocationRegionID: 12,
    LocationCountryID: 233,
    LocationStateID: 1436,
    Model: null,
    City: "Miami",
    Condition: undefined,
    Year: { start: null, end: null },
    PriceRange: { start: null, end: null },
    FeaturedNotFirst: true,
    VesselType: 0,
  });
  if (data) {
    const results = data.Results.map(mapSearchVesselToBoat);
    return {
      results,
    };
  }
};

const getFeatured = async () => {
  const data = await post("/ForSale/Vessel/Search", {
    Records: RESULTS_MAX_RECORDS_FEATURED,
    CompanyID: YATCO_API_MIAMI_YACHTING_COMPANY_ID,
  });
  data.results = data.Results.map(mapSearchVesselToBoat);
  return data;
};

const getById = async (id) => {
  const data = await get(`/ForSale/Vessel/${id}/Details/FullSpecsAll`);
  if (data.BasicInfo.VesselID) {
    return mapVesselToBoat(data);
  }
  return mapVesselToBoat({});
};

const mapSearchVesselToBoat = (vessel) => ({
  id: vessel.VesselID,
  name: vessel.VesselName,
  pictureURL: vessel.MainPhotoUrl,
  location:
    `${vessel.LocationCity ? `${vessel.LocationCity},` : ""}
    ${vessel.LocationState ? `${vessel.LocationState},` : ""}
    ${vessel.LocationCountry ? `${vessel.LocationCountry}` : ""}` ||
    "Not Specified",
  isFeatured: vessel.BrokerList.find(
    (broker) => broker.CompanyID === YATCO_API_MIAMI_YACHTING_COMPANY_ID
  ),
  price: vessel.AskingPriceFormatted,
  priceNumber: vessel.AskingPrice,
  builderID: vessel.BuilderID,
  vesselType: vessel.VesselType,
  mainCategoryText: vessel.MainCategoryText,
  year: vessel.ModelYear,
  model:
    vessel.Model === undefined || vessel.Model.trim() === ""
      ? "Model not specified"
      : vessel.Model,
});

const mapVesselToBoat = (vessel) => {
  const location = [
    vessel.BasicInfo.LocationCity,
    vessel.BasicInfo.LocationState,
    vessel.BasicInfo.LocationCountry,
  ]
    .filter((word) => word && !word.includes("N/A"))
    .join(", ");
  const manufacturerValue = [vessel.BasicInfo.Builder, vessel.BasicInfo.Model]
    .filter((word) => word)
    .join(" / ");

  return {
    id: vessel.BasicInfo.VesselID,
    name: vessel.BasicInfo.BoatName,
    pictureURL: vessel.BasicInfo.MainPhotoURL,
    location: location.trim() ? location : "Not Specified",
    //
    isFeatured:
      vessel.BasicInfo.BrokerID === YATCO_API_MIAMI_YACHTING_COMPANY_ID,
    price: vessel.BasicInfo.AskingPriceFormatted,
    length: vessel.Dimensions.LOA,
    //
    manufacturer: manufacturerValue,
    flag: vessel.BasicInfo.Flag,
    description: vessel.VD.VesselDescriptionShortDescriptionNoStyles,
    //
    identifier: vessel.BasicInfo.VesselID,
    //
    type: vessel.BasicInfo.MainCategory,
    year: vessel.BasicInfo.ModelYear,
    beam: vessel.Dimensions.Beam,
    draftMax: vessel.Dimensions.MaxDraft,
    draftMin: vessel.Dimensions.MinDraft,
    //
    cabins: vessel.Accommodations.StateRooms,
    sleeps: vessel.Accommodations.Sleeps,
    //
    singleBerths: vessel.Accommodations.Berths,
    //
    kingBerths: vessel.Accommodations.Berths,
    //
    fullBeamMaster: vessel.type || "Yes",
    //
    crewCabins: vessel.Accommodations.CrewQuarters,
    crewSleeps: vessel.Accommodations.CrewSleeps,
    maxSpeed: vessel.SpeedWeight.MaxSpeed,
    cruiseSpeed: vessel.SpeedWeight.CruiseSpeed,
    //
    fuelType: vessel.type || "Diesel",
    hullMaterial: vessel.HullDeck.HullMaterial,
    //
    hullShape: vessel.HullDeck.HullConfiguration,
    galleryPictures: vessel.PhotoGallery.map((photo) => photo.largeImageURL),
    mobileGalleryPictures: vessel.PhotoGallery.map(
      (photo) => photo.mediumImageURL
    ),
  };
};

const mapFiltersToSearch = (filters) => {
  return {
    BuilderID: filters.manufacturer?.id,
    LocationRegionID: filters.region?.id,
    LocationCountryID: filters.country?.id,
    LocationStateID: filters.state?.id,
    Model: filters.model ?? null,
    City: filters.city?.id,
    Condition: filters.condition === undefined ? 0 : filters.condition?.id,
    Year: {
      start: filters.minYear === "" ? null : filters.minYear,
      end: filters.maxYear === "" ? null : filters.maxYear,
    },
    PriceRange: {
      start: filters.minPrice === "" ? null : filters.minPrice,
      end: filters.maxPrice === "" ? null : filters.maxPrice,
    },
    FeaturedNotFirst: true,
  };
};

const mapTypeToSearch = (type) => {
  let vesselType;
  if (type === "Sail") {
    vesselType = 1;
  } else if (type === "Power") {
    vesselType = 2;
  } else {
    vesselType = 0;
  }
  return {
    VesselType: vesselType,
  };
};

const mapCategoryToSearch = (category) => {
  let filter;
  switch (category) {
    case "Motor Yacht":
      filter = {
        MainCategoryText: "Motor Yacht",
        SubCategoryID: undefined,
        VesselType: 2,
      };
      break;
    // Express Cruising -- Haven't  found the SUBCATEGORY ID for EXPRESS
    case "Express Cruisers":
      filter = {
        MainCategoryText: "Cruising Yacht",
        SubCategoryID: undefined,
        VesselType: 2,
      };
      break;
    case "Sport Fishing":
      filter = {
        MainCategoryText: "Sport Fisherman",
        SubCategoryID: undefined,
        VesselType: 2,
      };
      break;
    case "Center Consoles":
      filter = {
        MainCategoryText: "Boats",
        SubCategoryID: YATCO_API_SUBCATEGORY_CENTER_CONSOLE_ID,
        VesselType: 2,
      };
      break;
    case "Sailing Yachts":
      filter = {
        MainCategoryText: undefined,
        SubCategoryID: undefined,
        VesselType: 1,
      };
      break;
    default:
      filter = {};
      break;
  }
  return filter;
};

const Boats = { getAll, getFirstLoadData, getById, getFeatured };

export default Boats;
